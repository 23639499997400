<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Benutzer">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="handleRegionChange"
        ></region-chooser>
        <el-button type="primary" icon="fal fa-plus" @click="newUser">
          Neuer Benutzer
        </el-button>
      </template>

      <el-input
        v-model="computedSearchQuery"
        placeholder="Durchsuchen…"
        clearable
        style="max-width: 500px;"
      >
        <i slot="prefix" class="el-icon-search el-input__icon"></i>
      </el-input>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/admin/users?page=${currentPage}&query=${remoteSearchQuery}&region_id=${$store.state.auth.chosenRegion}`
        "
      >
        <template #loaded="{data}">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.users"
          >
            <el-table-column
              prop="email"
              label="E-Mail-Adresse"
            ></el-table-column>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column label="Berechtigungen">
              <template #default="scope">
                <el-tag
                  v-for="role in activatedRoles(scope.row.region_roles)"
                  :key="role"
                  :class="['role-tag', 'role-tag-' + role]"
                  size="small"
                >
                  {{ regionRoles[role] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Einladung" width="125">
              <template #default="scope">
                <el-tag
                  v-if="
                    !scope.row.invitation_created_at ||
                      scope.row.invitation_accepted_at
                  "
                  size="small"
                  type="success"
                >
                  Angenommen
                </el-tag>
                <el-tag v-else size="small" type="warning">Offen</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteUser"
                  subject="Benutzer"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import * as log from "loglevel"
import { debounce } from "lodash-es"
import UsersRepository from "@/repositories/users_repository.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"
import DeleteButton from "@/components/DeleteButton.js"

export default {
  metaInfo: {
    title: "Benutzer"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DataLoader,
    DeleteButton,
    ThePagination
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }
    }
    next()
  },
  data() {
    return {
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || "",
      regionRoles: {
        admin: "Administrator",
        events_manager: "Veranstaltungen",
        numbers_manager: "Notfallnummern",
        videos_manager: "Videos",
        articles_manager: "Artikel",
        institutions_manager: "Einrichtungen",
        news_manager: "Neuigkeiten",
        downloads_manager: "Downloads",
        analytics_manager: "Analytics"
      }
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    },
    activatedRoles() {
      return roles => Object.keys(roles).filter(k => roles[k] === true)
    }
  },
  methods: {
    handleRegionChange() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
          query: "",
          region_id: this.$store.state.auth.chosenRegion
        }
      })
    },
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    handleEdit(user) {
      this.$router.push({ name: "EditUserPage", params: { id: user.id } })
    },
    async deleteUser(user) {
      await UsersRepository.destroy(user.id)
      this.$refs.dataloader.loadData()
    },
    newUser() {
      this.$router.push({ name: "NewUserPage" })
    }
  }
}
</script>

<style lang="scss" scoped>
.role-tag {
  margin-right: 3px;
  margin-bottom: 3px;
}
</style>
